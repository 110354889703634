import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["panel"]

  connect() {
    console.log("Hi from terminos_controller")
  }

  open() {
    this.panelTarget.classList.remove("hidden");
    this.panelTarget.classList.add("flex");
    this.panelTarget.classList.remove("animate-fade-out");
    this.panelTarget.classList.add("animate-blurred-fade-in");
  }

  close() {
    this.panelTarget.classList.remove("animate-blurred-fade-in");
    this.panelTarget.classList.add("animate-fade-out");
    setTimeout(() => {
      this.panelTarget.classList.remove("flex");
      this.panelTarget.classList.add("hidden");
    }, 500);
  }
}