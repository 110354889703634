import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["visitantes", "anunciantes", "btn1", "btn2"]

  visitantes() {
    this.btn1Target.classList.add("bg-blue-light")
    this.btn2Target.classList.remove("bg-blue-light")

    this.anunciantesTarget.classList.add("hidden")
    this.visitantesTarget.classList.remove("hidden")
  }

  anunciantes() {
    this.btn1Target.classList.remove("bg-blue-light")
    this.btn2Target.classList.add("bg-blue-light")

    this.anunciantesTarget.classList.remove("hidden")
    this.visitantesTarget.classList.add("hidden")
  }
}