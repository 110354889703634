import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "preview", "button", "box", "hidden"]

  preview() {
    this.previewTarget.src = URL.createObjectURL(this.inputTarget.files[0])
    this.buttonTarget.classList.add("hidden")
    this.boxTarget.classList.remove("hidden")
  }

  open() {
    this.inputTarget.click()
  }

  close(e) {
    e.stopPropagation();
    this.previewTarget.src = ''
    this.inputTarget.value = ''
    this.buttonTarget.classList.remove("hidden")
    this.boxTarget.classList.add("hidden")
    if (this.hiddenTarget.value) {
      this.hiddenTarget.value = ''
    }
  }

}